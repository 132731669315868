<template>
    <div class="My">
        <div :class="{'myView': true, 'myViewApp': getIsApp() }">
            <header>
                <img :src="accountAvatar">
                <dl>
                    <dt>{{companyObj.realName}}</dt>
                    <dd>{{companyObj.email}}</dd>
                </dl>
                <ul>
                    <li>
                        <!-- <em>{{$m_unifiedTime(userUsedObj.expirationtime)}}</em> -->
                        <em>{{returnDate(userUsedObj.expirationtime)}}</em>
                        <!-- 到期时间 -->
                        <span>{{$t('mx_mobile.Client.1585277494992')}}</span>
                    </li>
                    <li>
                        <!-- 人 -->
                        <em>{{userUsedObj.authcount}}{{$t('mx_mobile.common.1665997940307')}}</em>
                        <!-- 许可人数 -->
                        <span>{{$t('mx_mobile.Client.1585639358585')}}</span>
                    </li>
                    <li>
                        <em>{{userUsedObj.spacecount}}G</em>
                        <!-- 云空间 -->
                        <span>{{$t('mx_mobile.Client.1585277492665')}}</span>
                    </li>
                </ul>
            </header>

            <van-cell-group>

                <div @click="$router.push('/my/order')">
                    <van-cell value="" icon=" " is-link>
                        <template slot="title">
                            <i class="iconfont icon-work-sheet cell_icon" style="color:#d0021b"></i>
                            <!-- 我的订单 -->
                            <span class="van-cell-text cell-text">{{$t('mx_mobile.Client.1585639394231')}}</span>
                        </template>
                    </van-cell>
                </div>
                <div @click="$router.push('/my/setting')">
                    <van-cell value="" icon=" " is-link>
                        <template slot="title">
                            <i class="iconfont icon-setting cell_icon" style="color:#FFB735"></i>
                            <!-- 我的设置 -->
                            <span class="van-cell-text cell-text">{{$t('mx_mobile.App.1584683241860')}}</span>
                        </template>
                    </van-cell>
                </div>
                <div @click="$router.push('/my/payment')">
                    <van-cell value="" icon=" " is-link>
                        <template slot="title">
                            <i class="iconfont icon-book cell_icon" style="color:#FFB735"></i>
                            <!-- 付款方式 -->
                            <span class="van-cell-text cell-text">{{$t('mx_mobile.Client.1585282870084')}}</span>
                        </template>
                    </van-cell>
                </div>
                <!--<van-cell value="" icon=" " is-link>
          <template slot="title">
            <i class="iconfont icon-msg cell_icon" style="color:#5EA3F6"></i>
            <span class="van-cell-text cell-text">我的反馈</span>
          </template>
        </van-cell> -->

                <van-cell :value="formatPhoneNumber(phoneNumber)" @click="dialHandler(phoneNumber)" icon=" ">
                    <template slot="title">
                        <i class="iconfont icon-tel cell_icon" style="color:#8BD867"></i>
                        <!-- 客服电话 -->
                        <span class="van-cell-text cell-text">{{$t('mx_mobile.Client.1585639506911')}}</span>
                    </template>
                </van-cell>

                <!-- <van-cell value="" icon=" " is-link>
          <template slot="title">
            <i class="iconfont icon-tips cell_icon" style="color:#FF7165"></i>
            <span class="van-cell-text cell-text">关于孚盟</span>
          </template>
        </van-cell> -->

            </van-cell-group>
            <div v-if="inFumaApp || getIsApp()" style="margin:20px 0">
                <!-- 退出登录 -->
                <van-button @click="signOutApp()" size="large">{{$t('mx_mobile.Client.1585639508967')}}</van-button>
                <div class="userLicense">
                    <!-- 孚盟MX用户许可协议及隐私策略 -->
                    <div @click="openUserLicense()">《{{$t('mx_mobile.common.1665998116225')}}》</div>
                </div>
            </div>
            <div v-if="!getIsApp()" style="margin:20px 0">
                <!-- 退出登录 -->
                <van-button @click="signOutWeb()" size="large">{{$t('mx_mobile.Client.1585639508967')}}</van-button>
            </div>
        </div>

    </div>
</template>

<script>
import titleMixin from '../../../mixin/title'
import { clearItem, getIsApp } from '@/libs/utils'
import hybrid from '@/libs/hybrid'

export default {
    name: 'Center',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Client.1585639563207') }, // '我的'
    data() {
        return {
            accountAvatar: '',
            companyObj: {},
            userUsedObj: {},
            pageUrl: '',
            inFumaApp: false,
            phoneNumber: '4008811518'
        }
    },
    created() {
        this.getCompanysetting() // 获取企业配置信息
        this.getUseSpace() // 获取企业空间量
        this.pageUrl = this.$route.path
    },
    mounted() {
        if (this.Global.utils.getItem('isFumaApp')) {
            this.inFumaApp = true
        }
    },
    methods: {
        getIsApp,
        returnDate(date) {
            date = (date && date != '') ? date.split(' ')[0] : ''
            return date
        },
        signOutWeb() {
            clearItem()
            this.$router.replace('/')
        },
        /* 在app内退出登录 */
        signOutApp() {
            if (getIsApp()) {
                hybrid.logout()
                // this.$router.replace({path: '/login'})
            }
            clearItem()
        },
        openUserLicense() {
            // let message = JSON.stringify({ type: 'openLink', data: 'https://fumamx.com/pc/userLicenseAgreement' })
            // window.ReactNativeWebView ? window.ReactNativeWebView.postMessage(message)
            //     : window.postMessage(message)
            // '用户服务与隐私协议'
            window.open({ url: 'https://fumamx.com/pc/userLicenseAgreement', isShare: false, fileName: this.$t('mx_mobile.common.1665998415687') })
        },
        // 获取企业配置信息
        getCompanysetting() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.account_get, { params: {} }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.companyObj = res.data.data
                    let size = '64x64'
                    this.accountAvatar = this.getGlobalImgSrc(this.companyObj.avatar, size)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        /**
         * 取云空间使用量
         * **/
        getUseSpace() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.payCenter_getAuth, { params: { cid: this.companie.cId } }).then(res => {
                // this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.payCenter_getAuth, {params: {cid: '11644'}}).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    // console.log(res.data.data)
                    this.userUsedObj = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        dialHandler(number) {
            if (getIsApp()) {
                hybrid.commitMessage('callPhone', number)
            } else {
                location.href = `tel: ${number}`
            }
        },
        formatPhoneNumber(str) {
            // Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '')

            // Check if the input is of correct length
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

            if (match) {
                return `${match[1]}-${match[2]}-${match[3]}`
            }
            return str
        }
    },
    watch: {
        $route(to, from) {
            if (to.path === this.pageUrl) {
                // this.setMenu()
            }
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
